//
//	Buttons.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


// import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import './Buttons.css';


export function Buttons(props) {
  return (
    <nav className="flex fc gap4 max260 mxa mx4">
      {props.children}
    </nav>
  )
}


export function Button(props) {
// props.size = s — button size

  // const location = useLocation();
  const navigate = useNavigate();
  
  // useEffect(() => {
  //   console.log('Current location is ', location)
  // }, [location])
  
  let buttonSize = ''
  if (props.size) buttonSize += 'button_' + props.size;
  
  return (
    <a className={clsx(
        'button',
        props.size !== undefined && buttonSize,
        props.className
      )}
      role="button"
      // onClick={() => navigate(props.to)}
      onClick={props.onClick}>{props.name}</a>
  )
}


{/* <Button name="Main" onClick={() => navigate('one')}/>
<Link to="/one">Page One</Link>
<button onClick={() => navigate('one')}>Page One</button>
<button onClick={() => navigate('two', { replace: false })}>Page Two</button> */}
