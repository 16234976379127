//
//	FindGame.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { Buttons, Button } from '../components/Buttons';
import { Block } from '../components/Block';
import games from '../data/games.json';


export const FindGame = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const webApp = window.Telegram.WebApp;
  
  useEffect(() => {
    webApp.BackButton.show();
    webApp.onEvent('backButtonClicked', () => {
      navigate('/');
    });
  }, [location])
  
  // const connect = () => {
    // webApp.showAlert('Connection failed')
  // }
  
  return (
    <>
      <Header text="Find Game"/>
      <Block className="flex fc gap3">
        <div className="flex aic jcsa mb3">
          <div className="fs18 fw700" style={{borderBottom: 'solid 3px'}}>Play global</div>
          <div className="fs18 pointer op2" onClick={() => {
            webApp.showAlert('Invites will be available later')
          }}>Invites (0)</div>
        </div>
        <div className="mb2">
          <input type="search" name="search" placeholder="Search user"/>
        </div>
        {
          games.map((game, index) => {
            return (
              <div key={index} className="flex aic">
                <div>
                  <div className="fs18 fw500">@{game.player1}</div>
                  {/* <div className="fs14 fw500 op4">10 games, 10 wins</div> */}
                </div>
                <Button size="s" name="Play" className="mla" onClick={() => {
                  webApp.showAlert('Connection failed')
                }}/>
              </div>
            )
          })
        }
        <div className="mt4 ac fs14 op5">... and more 2748 games</div>
      </Block>
      <Buttons>
        {/* <Button name="Go Back" to="/"/> */}
        <Button name="Go Back" onClick={() => navigate('/')}/>
      </Buttons>
    </>
  );
}
