//
//	Block.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import './Block.css';


export function Block(props) {
  return (
    <div className={clsx('block mx4 my6 p6 r6 sh1', props.className || '')}>
      {props.children}
    </div>
  )
}
